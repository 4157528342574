@font-face {
    font-family: 'IDXSans Regular';
    src: url('../fonts/IDXSans-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
 
@font-face {
    font-family: 'IDXSerif Regular';
    src: url('../fonts/IDXSerif-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
 
@font-face {
    font-family: 'IDXSans Bold';
    src: url('../fonts/IDXSans-Bold.otf');
    font-weight: normal;
    font-style: normal;
}
 
@font-face {
    font-family: 'IDXHeadline Heavy';
    src: url('../fonts/IDXHeadline-Heavy.otf');
    font-weight: normal;
    font-style: normal;
}