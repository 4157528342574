//******Custom variables******
$id-teal-lighter:   #4fd2c7;
$id-teal:           #00a699;
$id-teal-darker:    #00645c;
$id-blue:           #1f3357;
$id-orange:         #f37b21;

$filter-box-bg:     #A5ADBC !default;

//******Bootstrap variables overwrite
$white:              #fff !default;
$gray-100:           #F6F6F6 !default;
$gray-200:           #DDDEE2 !default;
$gray-300:           #dee2e6 !default;
$gray-400:           #DDDEE2 !default;
$gray-500:           #adb5bd !default;
$gray-600:           #979797 !default;
$gray-700:           #818388 !default;
$gray-900:           #000 !default;
$light:                $gray-500 !default;
$dark:                 $gray-700 !default;
$text-color:           $id-blue;
$primary:              $id-blue !default;
$secondary:          #79859A !default;
$body-color:           $id-blue !default;
$table-color:          $body-color;
$card-border-color:    $gray-300;

//fonts
$font-family-base:      'IDXSans Regular';
$headings-font-family:  'IDXSans Bold';

//Table
$table-cell-padding-x: .5rem;

//Tabs
$nav-tabs-border-radius:  0 !default;

//Tooltip
$tooltip-bg:            $gray-300;
$tooltip-color:         $body-color;
$tooltip-font-size:     .75rem;

//Nav tabs
$nav-tabs-font-family:      $headings-font-family;
$dropdown-item-padding-y:   .4rem;

//border
$border-light-color:            $gray-300;


//Utilities
$btn-border-radius:     0;
