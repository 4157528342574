@import 'react-date-range/dist/styles.css'; // main css file
@import 'react-date-range/dist/theme/default.css'; // theme css file

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.form-select:focus {
  border-color: #8f99ab;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 51, 87, 0.25);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  max-height: 200px;
  /* Adjust as needed */
  overflow-y: auto;
}

.dropdown-menu li {
  padding-left: 0.5rem;
  font-size: 13px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #0078D7;
  color: #fff;
}

.truncate-text {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container {
  max-height: 350px;
  overflow-y: auto;
}